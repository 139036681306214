import "./Song";
import { Song } from "./Song";
export class SongList {
    constructor(object) {
        this.list = [];
        this.hasTime = false;
        this.hasTitle = false;
        this.hasPerformer = false;
        this.hasComposer = false;
        this.hasNumber = false;
        this.hasAlbum = false;
        this.hasGenre = false;
        this.hasYear = false;
        this.hasDuration = false;
        object === null || object === void 0 ? void 0 : object.forEach(entry => {
            entry === null || entry === void 0 ? void 0 : entry.forEach(object => {
                const song = new Song(object);
                this.list.push(song);
                if (song.title) {
                    this.hasTitle = true;
                }
                if (song.performer) {
                    this.hasPerformer = true;
                }
                if (song.composer) {
                    this.hasComposer = true;
                }
                if (song.number) {
                    this.hasNumber = true;
                }
                if (song.album) {
                    this.hasAlbum = true;
                }
                if (song.genre) {
                    this.hasGenre = true;
                }
                if (song.year) {
                    this.hasYear = true;
                }
                if (song.time) {
                    this.hasTime = true;
                }
                if (song.duration) {
                    this.hasDuration = true;
                }
            });
        });
    }
}

export class Song {
    constructor(song) {
        this.time = song.time;
        this.title = song.title;
        this.performer = song.performer;
        this.composer = song.composer;
        this.number = song.number;
        this.album = song.album;
        this.genre = song.genre;
        this.year = song.year;
        this.duration = song.duration;
    }
    get spotify() {
        return this.title === "Airport"
            && this.performer === "The Motors";
    }
    get spotifyWeb() {
        if (this.title === "Airport"
            && this.performer === "The Motors") {
            return window.encodeURI(`https://open.spotify.com/track/6F0CQMioZrqgkp3oHx4hpY`);
        }
        else {
            return null;
        }
    }
    get spotifyApp() {
        if (this.title === "Airport"
            && this.performer === "The Motors") {
            return window.encodeURI(`spotify:track:6F0CQMioZrqgkp3oHx4hpY`);
        }
        else {
            return null;
        }
    }
}

import { SongList } from "./SongList";
export class Episode {
    constructor(episode, group) {
        this.group = group;
        this.id = episode.id;
        this.title = episode.title;
        this.date = episode.date ? new Date(Date.parse(episode.date)) : null;
        this.comment = episode.comment;
        // fixme: 2 unterschiedliche Formate sollten nicht unterstützt werden.
        if (episode.parts && episode.parts[0] && episode.parts[1]) {
            this.songs = new SongList([episode.parts[0].songs, episode.parts[1].songs]);
        }
        else {
            this.songs = new SongList(episode.songs);
        }
    }
    get dateFormat() {
        if (this.date) {
            const month = this.date.getUTCMonth() + 1;
            return this.date.getUTCDate() + "." + (month > 9 ? "" : "0") + month + "." + this.date.getUTCFullYear();
        }
        else {
            return "unbekannt";
        }
    }
    get titleFormat() {
        if (this.title) {
            return this.title;
        }
        else if (this.group.title) {
            return `${this.group.title} vom ${this.dateFormat}`;
        }
        else {
            return `${this.group.broadcast.title} vom ${this.dateFormat}`;
        }
    }
}

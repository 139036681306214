import { Episode } from "./Episode";
export class Broadcast {
    constructor(broadcast) {
        this.id = broadcast.id;
        this.title = broadcast.title;
        this.station = broadcast.station;
        this.moderator = broadcast.moderator;
        this.groups = new Map();
        // this.years = new Map();
        broadcast.groups.forEach((group) => {
            if (this.groups.has(group.id)) {
                throw new Error(`Duplicate group id error: id='${group.id}'.`);
            }
            const g = new Group(group, this);
            this.groups.set(group.id, g);
            // const year = e.date ? e.date.getFullYear().toString() : "unknown";
            // let list = this.years.get(year);
            // if (list) {
            //   list.push(e);
            // } else {
            //   list = [e];
            //   this.years.set(year, list);
            // }
        });
        let previous;
        this.groups.forEach((group) => {
            group.episodes.forEach((episode) => {
                if (previous) {
                    previous.nextId = episode.group.id + "-" + episode.id;
                    episode.previousId = previous.group.id + "-" + previous.id;
                }
                previous = episode;
                if (episode.songs.list.length > 0) {
                    this.latestEpisode = previous;
                }
            });
        });
    }
    get groupsArray() {
        return Array.from(this.groups.keys());
    }
    get show() {
        return this.title;
    }
}
export class Group {
    constructor(group, broadcast) {
        this.broadcast = broadcast;
        this.id = group.id;
        this.title = group.title;
        this.station = group.station;
        this.episodes = new Map();
        // let previous: Episode;
        group.episodes.forEach((episode) => {
            if (this.episodes.has(episode.id)) {
                throw new Error(`Duplicate episode id error: id='${episode.id}'.`);
            }
            const e = new Episode(episode, this);
            this.episodes.set(episode.id, e);
        });
    }
    get episodesArray() {
        return Array.from(this.episodes.values());
    }
    get stationDisplay() {
        if (this.station) {
            return this.station;
        }
        else {
            return this.broadcast.station;
        }
    }
}
